import { Container, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { IStatistics } from '../../../interfaces/IStatistics'
import { api } from '../../../services/api'

import {
  UserStatistic,
  UserStatisticsSection,
  UserStatisticsTitle,
  UserStatisticSubTitle,
  UserStatisticTitle,
} from '../../../styles/components/app/home/UserStatistics'

import Icon from '../../../styles/shared/Icon'

import { useEffect, useState } from 'react'

export default function UserStatistics() {
  const [statistics, setStatistics] = useState<IStatistics>()

  const get = async () => {
    await api.get('v1/users/statistics').then((response) => {
      setStatistics(response.data)
    })
  }

  useEffect(() => {
    get()
  }, [])

  return (
    <UserStatisticsSection>
      <Container maxWidth="container.xl">
        <UserStatisticsTitle>Status</UserStatisticsTitle>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
          <UserStatistic>
            <Icon className="fa-solid fa-server" />
            <UserStatisticTitle>Serviços</UserStatisticTitle>
            <UserStatisticSubTitle>
              {statistics ? (
                statistics?.services
              ) : (
                <Skeleton
                  mt={4}
                  startColor="#232427"
                  endColor="#1a1c1e"
                  height="20px"
                />
              )}
            </UserStatisticSubTitle>
          </UserStatistic>
          <UserStatistic>
            <Icon className="fa-solid fa-file" />
            <UserStatisticTitle>Faturas</UserStatisticTitle>
            <UserStatisticSubTitle>
              {statistics ? (
                statistics?.invoices
              ) : (
                <Skeleton
                  mt={4}
                  startColor="#232427"
                  endColor="#1a1c1e"
                  height="20px"
                />
              )}
            </UserStatisticSubTitle>
          </UserStatistic>
          <UserStatistic>
            <Icon className="fa-solid fa-wallet" />
            <UserStatisticTitle>Recargas</UserStatisticTitle>
            <UserStatisticSubTitle>
              {statistics ? (
                statistics?.recharges
              ) : (
                <Skeleton
                  mt={4}
                  startColor="#232427"
                  endColor="#1a1c1e"
                  height="20px"
                />
              )}
            </UserStatisticSubTitle>
          </UserStatistic>
          <UserStatistic>
            <Icon className="fa-solid fa-headset" />
            <UserStatisticTitle>Tickets</UserStatisticTitle>
            <UserStatisticSubTitle>
              {statistics ? (
                statistics?.tickets
              ) : (
                <Skeleton
                  mt={4}
                  startColor="#232427"
                  endColor="#1a1c1e"
                  height="20px"
                />
              )}
            </UserStatisticSubTitle>
          </UserStatistic>
        </SimpleGrid>
      </Container>
    </UserStatisticsSection>
  )
}
