import type { NextPage } from 'next'

import Layout from '../../layouts/app/Layout'

import UserInformations from '../../components/app/shared/UserInformations'
import UserStatistics from '../../components/app/home/UserStatistics'
import UserServices from '../../components/app/shared/UserServices'
import UserTickets from '../../components/app/shared/UserTickets'

const Home: NextPage = () => {
  return (
    <Layout>
      <UserInformations />
      <UserStatistics />
      <UserServices />
      <UserTickets />
    </Layout>
  )
}

export default Home
