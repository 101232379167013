import {
  Container,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
} from '@chakra-ui/react'

import { TableCustom } from '../../../styles/shared/Table'
import Icon from '../../../styles/shared/Icon'
import Button from '../../../styles/shared/Button'

import {
  UserServicesHeader,
  UserServicesSection,
  UserServicesTitle,
} from '../../../styles/components/app/shared/UserServices'

import { api } from '../../../services/api'

import { IUserService } from '../../../interfaces/IUserService'

import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import Router from 'next/router'
import Pagination from './Pagination'

export default function UserServices() {
  const [loading, setLoading] = useState(false)

  const [services, setServices] = useState<IUserService[]>([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const get = async (page: number) => {
    setLoading(true)

    await api.get(`v1/users/services/${page}`).then((response) => {
      setServices(response.data.data)
      setTotalPages(response.data.meta.last_page)

      setLoading(false)
    })
  }

  useEffect(() => {
    get(1)
  }, [])

  return (
    <UserServicesSection>
      <Container maxWidth="container.xl">
        <UserServicesHeader>
          <UserServicesTitle>Serviços</UserServicesTitle>
          <Button padding="10px" onClick={() => Router.push('/app/purchase')}>
            Contratar serviço
          </Button>
        </UserServicesHeader>
        <TableContainer>
          <TableCustom variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Plano</Th>
                <Th>Preço</Th>
                <Th>Localização</Th>
                <Th>Expira em</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={7}>
                    <Skeleton
                      startColor="#232427"
                      endColor="#1a1c1e"
                      height="30px"
                      borderRadius={0}
                    />
                  </Td>
                </Tr>
              ) : services.length > 0 ? (
                services?.map((service, index) => (
                  <Tr key={index}>
                    <Td>#{service.id}</Td>
                    <Td>{service.service.name}</Td>
                    <Td>
                      R${' '}
                      {service.service.price.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      })}
                    </Td>
                    <Td>{service.service.serviceLocation?.name}</Td>
                    <Td>
                      {service.expiration_date
                        ? format(
                            new Date(service.expiration_date),
                            "dd/MM/yyyy 'às' HH:mm",
                          )
                        : '...'}
                    </Td>
                    <Td>
                      {(service.status === 'ACTIVE' && 'Ativo') ||
                        (service.status === 'PENDING' && 'Pendente') ||
                        (service.status === 'SUSPENDED' && 'Suspenso') ||
                        (service.status === 'CANCELED' && 'Cancelado')}
                    </Td>
                    <Td>
                      <Button
                        padding="3px 10px"
                        onClick={() =>
                          Router.push(
                            `/app/service/${
                              (service.service.type === 'VPS' && 'vps') ||
                              (service.service.type === 'DEDICATED_SERVER' &&
                                'dedicated') ||
                              (service.service.type === 'MINECRAFT' &&
                                'minecraft')
                            }/${service.id}`,
                          )
                        }
                      >
                        <Icon className="fa-solid fa-eye" size="14px" />
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={7}>Você não tem nenhum serviço.</Td>
                </Tr>
              )}
            </Tbody>
          </TableCustom>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          get={get}
        />
      </Container>
    </UserServicesSection>
  )
}
