import styled from '@emotion/styled'

export const UserStatisticsSection = styled.section`
  padding-block: 50px;
`

export const UserStatisticsTitle = styled.h2`
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const UserStatistic = styled.div`
  display: flex;
  flex-direction: column;
  background: #1a1c1e;
  padding: 20px;

  i {
    font-size: 40px;
    color: #0bc5ea;
  }
`

export const UserStatisticTitle = styled.p`
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 14px;

  margin-top: 20px;
`

export const UserStatisticSubTitle = styled.h4`
  font-size: 30px;
`
